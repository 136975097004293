var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    attrs: {
      "id": "topnav-login"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "border-bottom-nav",
    attrs: {
      "id": "navigation"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('ul', {
    directives: [{
      name: "scroll-spy-active",
      rawName: "v-scroll-spy-active",
      value: {
        selector: 'li.has-submenu'
      },
      expression: "{ selector: 'li.has-submenu' }"
    }],
    staticClass: "navigation-menu mb-0"
  }, [_c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "https://awarisgroup.com/"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left mr-1"
  }), _vm._v(_vm._s(_vm.$t("navbar.homepage")))])])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai"
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" " + _vm._s(_vm.$t("lang")) + " "), _c('span', {
    staticClass: "lang-bg ml-2"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])])])])])]), _c('div', {
    staticClass: "menu-extras"
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('a', {
    staticClass: "navbar-toggle",
    class: {
      open: _vm.isCondensed === true
    },
    on: {
      "click": function ($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_vm._m(0)])])])]), _c('section', {
    staticClass: "bg-home d-flex align-items-center"
  }, [_c('div', {
    staticClass: "bg-overlay bg-overlay-white bg-overlay-img"
  }), _c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "100px"
    }
  }, [_c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c('div', {
    staticClass: "card login-page bg-white shadow rounded-login border-0"
  }, [_c('div', {
    staticClass: "card-body-login"
  }, [_vm.registerCompleted ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" Akaun anda telah berjaya disahkan. " + _vm._s(_vm.userStatus == "0" ? "Sila tunggu pengesahan AWARIS sebelum log masuk." : "Sila log masuk.") + " ")]) : _vm._e(), _vm._m(1), _c('form', {
    staticClass: "login-form mt-4",
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("username")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('user-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.username,
      expression: "form.username"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('username'),
      "name": "username",
      "autocomplete": "username",
      "required": ""
    },
    domProps: {
      "value": _vm.form.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "username", $event.target.value);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("password")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('key-icon', {
    staticClass: "fea icon-sm icons"
  }), _vm.passwordFieldType === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "required": "",
      "placeholder": _vm.$t('password'),
      "name": "current-password",
      "autocomplete": "current-password",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.password) ? _vm._i(_vm.form.password, null) > -1 : _vm.form.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "password", $$c);
        }
      }
    }
  }) : _vm.passwordFieldType === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "required": "",
      "placeholder": _vm.$t('password'),
      "name": "current-password",
      "autocomplete": "current-password",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.form.password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "required": "",
      "placeholder": _vm.$t('password'),
      "name": "current-password",
      "autocomplete": "current-password",
      "type": _vm.passwordFieldType
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }), _c('div', [_c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "0%",
      "right": "15%"
    },
    on: {
      "click": _vm.switchVisibility
    }
  }, [_vm.passVisible ? _c('b-icon-eye', {
    staticClass: "fea icon-sm icons"
  }) : _vm._e(), !_vm.passVisible ? _c('b-icon-eye-slash', {
    staticClass: "fea icon-sm icons"
  }) : _vm._e()], 1)])], 1)]), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('p', {
    staticClass: "forgot-pass mb-3"
  }, [_c('router-link', {
    staticClass: "text-dark font-weight-bold",
    attrs: {
      "to": {
        name: 'password-create'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("login.forgot-password")))])], 1)])]), _c('div', {
    staticClass: "col-lg-12 mb-0"
  }, [_c('button', {
    staticClass: "\n                      btn btn-primary btn-block\n                      spinner spinner-light spinner-right\n                    "
  }, [_vm._v(" " + _vm._s(_vm.$t("log-in")) + " "), _vm.isLogin ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])]), _c('div', {
    staticClass: "col-12 text-center"
  }, [_c('p', {
    staticClass: "mb-0 mt-3"
  }, [_c('small', {
    staticClass: "text-dark mr-2"
  }, [_vm._v(_vm._s(_vm.$t("login.account")))]), _c('router-link', {
    staticClass: "text-dark font-weight-bold",
    attrs: {
      "to": "/sign-up"
    }
  }, [_vm._v(_vm._s(_vm.$t("register")))])], 1)])])])])])])])]), _c('div', [_c('footer', {
    staticClass: "footer-login footer-bar"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _c('br'), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE")])])])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lines"
  }, [_c('span'), _c('span'), _c('span')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-img"
  }, [_c('img', {
    staticClass: "img-center",
    attrs: {
      "src": "/images/logo/AWARIS_Logo_Col.png",
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }